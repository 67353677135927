import React, { useState, useEffect } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import "../styles/mobileSidebar.scss"

import Check from "../assets/checkmark.svg"

const Announcement = (props) => {
    return(
        <div className="announcementCTA">
            <h5>{props.title}</h5>
            <p>{props.description}</p>
            <a href={props.link} target="_blank" className={props.color} >{props.buttonText}</a>
        </div>
    )
}

const MobileSidebar = (props) => {

    const data = useStaticQuery(graphql`
    query mobileAnnouncementQuery {
      allAirtableAnnouncements {
        nodes {
            data {
            title
            description
            buttonLink
            buttonTitle
            buttonColor
            }
        }
        }
    }
  `)

  const announcementsArray = data.allAirtableAnnouncements.nodes
  console.log(announcementsArray)
    
    return(
        <div className="mobileSidebar">
            {
                announcementsArray.map(node => {
                    console.log(node)
                    return (
                        <Announcement 
                            title={node.data.title}
                            description={node.data.description}
                            buttonText={node.data.buttonTitle}
                            link={node.data.buttonLink}
                            color={node.data.buttonColor}/>
                    )
                })
            }
        </div>
    )
}

export default MobileSidebar