import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../layouts/index"
import Seo from "../components/seo"
import Logo from "../assets/logo.svg"
import StaticLogo from "../assets/flux-logo.svg"
import MobileSidebar from "../components/mobileSidebar"

import '../styles/index.scss'

// export const query = graphql`
//   query testEventQuery {
//     allAirtable(filter: {table: {eq: "events"}}) {
//       nodes {
//         data {
//           title
//           dateTime
//           duration
//           type
//           moreInfoLink
//           zoomLink
//           publishingStatus
//         }
//       }
//     }
//   }
// `

const IndexPage = (props) => {
  // const {data, errors} = props

  // const eventArray = data.allAirtable.nodes
  // console.log(eventArray)

  return(
    <>
      <Seo title="Home" />
      <div className="home">
        {/* <Logo/> */}
        <StaticLogo />
        <p>FLUX is a student-run organization focused on User Experience (UX) Design at Savannah College of Art and Design. We are a community of thinkers and innovators invested in creative problem solving using design, technology, and empathy. We host a variety of events, workshops, talks, and employer visits year-round to empower the next generation of designers.</p>
        <p>For collaborations and inquires, please email scadflux@gmail.com</p>
      </div>
      <MobileSidebar />
    </>
  )
}

export default IndexPage
